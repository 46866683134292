import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [error, setError] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setEmailVerified(user.emailVerified);
      } else {
        setUser(null);
        setEmailVerified(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    setLoading(true);
    setLoadingMessage("ログイン中...");
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      setEmailVerified(userCredential.user.emailVerified);
      if (!userCredential.user.emailVerified) {
        alert(
          "メールアドレスが確認されていません。確認メールを再送信しました。メールを確認してください。"
        );
        await sendEmailVerification(userCredential.user);
      }
      setError(null);
    } catch (error) {
      setError("メールアドレスまたはパスワードが正しくありません");
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    setIsSigningUp(false);
  };

  const handlePasswordReset = async (email) => {
    setLoading(true);
    setLoadingMessage("Loading...");
    try {
      await auth.sendPasswordResetEmail(email);
      alert(
        "パスワードリセットメールを送信しました。メールを確認してください。"
      );
    } catch (error) {
      alert("パスワードリセットメールの送信に失敗しました: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReauthenticate = async () => {
    setLoading(true);
    setLoadingMessage("ログイン状態確認中...");
    try {
      await user.reload();
      setEmailVerified(user.emailVerified);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      {loading ? (
        <div className="loading-overlay">
          <div className="loading-spinner">{loadingMessage}</div>
        </div>
      ) : user ? (
        emailVerified ? (
          <Dashboard
            user={user}
            onReauthenticate={handleReauthenticate}
            setLoading={setLoading}
            setLoadingMessage={setLoadingMessage}
          />
        ) : (
          <div className="email-verification">
            <p>
              メールアドレスが確認されていません。メールを確認してください。
            </p>
            <button onClick={async () => await sendEmailVerification(user)}>
              確認メールを再送信
            </button>
            <button onClick={() => auth.signOut()}>ログアウト</button>
            <button onClick={handleReauthenticate}>メールを確認しました</button>
          </div>
        )
      ) : isSigningUp ? (
        <SignUp
          onSignUp={handleSignUp}
          onBackToLogin={() => setIsSigningUp(false)}
        />
      ) : (
        <Login
          onLogin={handleLogin}
          onSignUp={() => setIsSigningUp(true)}
          onPasswordReset={handlePasswordReset}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
}

export default App;
